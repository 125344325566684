import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay"
import { Button } from "./Button"
import { Image, Text } from "./Core"
import TheaterVideo from "@components/Theater/TheaterVideo"
import DropdownButton from "../components/DropdownButton"

const SingleProcedureHeroBasic = ({
  desktopImage,
  mobileImage,
  heading,
  language,
  youtube,
  dropdownButton,
  goBackUrl
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const copyright = `Copyright ©Nuvolum ${new Date().getFullYear()}. All Rights Reserved.`

  return (
    <div className="sphb relative">
      <Image wrapperClassName="is-hidden-touch" publicId={desktopImage} />
      <Image wrapperClassName="is-hidden-desktop" publicId={mobileImage} />
      {goBackUrl && (
        <Button
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          href={language === "es" ? "/es/procedimientos/" : goBackUrl}
        />
      )}
      <div className="sphb__heading">
        <div>
          <Text as="h1" text={heading} />
          <div className="sphb__btn-stack">
            {youtube && (
              <div>
                <TheaterVideo
                  language={language}
                  videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
                  controls
                  playing
                  //   overVideo
                  hasWatchVideo
                  buttonClass="minimal is-hidden-touch"
                />
              </div>
            )}
            {dropdownButton && dropdownButton.hasThisSection && (
              <div className="is-hidden-touch mt-1">
                <DropdownButton
                  isCenteredMobile
                  heading="Other Locations"
                  links={dropdownButton.links}
                />
              </div>
            )}
          </div>
          {youtube && (
            <div className=" is-hidden-desktop">
              <TheaterVideo
                language={language}
                videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
                controls
                playing
                asWrapper
                wrapperClassName="sphb__mobile-theater"
                hasWatchVideo
                buttonClass="contained">
                <FontAwesomeIcon icon={faPlay} />
              </TheaterVideo>
            </div>
          )}
          <span className="sphb__copyright">{copyright}</span>
        </div>
      </div>
    </div>
  )

  return (
    <div className={`sph basic rounded-bottom`}>
      <Image publicId={desktopImage} />
      {goBackUrl && (
        <Button
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          href={language === "es" ? "/es/procedimientos/" : goBackUrl}
        />
      )}
      <div className="sphb__heading">
        <Text as="h1" text={heading} />
        <div className="sphb__btn-stack">
          {youtube && (
            <div>
              <TheaterVideo
                language={language}
                videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
                controls
                playing
                //   overVideo
                hasWatchVideo
                buttonClass="minimal is-hidden-touch"
              />
            </div>
          )}
          {dropdownButton && dropdownButton.hasThisSection && (
            <div className="is-hidden-touch mt-1">
              <DropdownButton
                isCenteredMobile
                heading="Other Locations"
                links={dropdownButton.links}
              />
            </div>
          )}
        </div>
        {youtube && (
          <div className=" is-hidden-desktop">
            <TheaterVideo
              language={language}
              videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
              controls
              playing
              asWrapper
              wrapperClassName="sphb__mobile-theater"
              hasWatchVideo
              buttonClass="contained">
              <FontAwesomeIcon icon={faPlay} />
            </TheaterVideo>
          </div>
        )}
        <span className="sphb__copyright">{copyright}</span>
      </div>
    </div>
  )
}

export default SingleProcedureHeroBasic
