import React from "react"
import { Image, Text } from "./Core"
import { Button } from "./Button"
import "./FeaturedCase.scss"

const FeaturedCase = ({ cases }) => {
  const has1 = cases.length === 1

  if (has1) {
    const patient = cases[0]
    return (
      <div className="fc has-1">
        <Text
          as="h3"
          className="has-text-centered"
          style={{ textTransform: "capitalize" }}
          text={patient.caseName.toLowerCase()}
        />
        <div className="fc-ba">
          <div className="fc-ba-card">
            <Image publicId={patient.beforeAfterSection.beforeImage} />
            <span>Before</span>
          </div>
          <div className="fc-ba-card">
            <Image publicId={patient.beforeAfterSection.afterImage} />
            <span>After</span>
          </div>
        </div>

        <div className="fc-blurb">
          <Text text={patient.metaDescription} />
          <Button
            buttonText="VIEW CASE"
            minimal
            href={`/${patient.title}`}
            destination="internal"
            className="mx-auto"
          />
        </div>
      </div>
    )
  }
  return (
    <div className="fc has-many">
      <Text as="h3" className="has-text-centered" text="Featured Experiences" />

      <div className="fc-cases">
        {cases.map(patient => (
          <div className="fc-case-row">
            <div className="fc-ba">
              <div className="fc-ba-card">
                <Image publicId={patient.beforeAfterSection.beforeImage} />
                <span>Before</span>
              </div>
              <div className="fc-ba-card">
                <Image publicId={patient.beforeAfterSection.afterImage} />
                <span>After</span>
              </div>
            </div>

            <div>
              <Text
                as="h4"
                style={{ textTransform: "capitalize" }}
                text={patient.caseName.toLowerCase()}
              />
              <Text className="mt-1" text={patient.metaDescription} />
              <Button
                buttonText="VIEW CASE"
                minimal
                href={`/${patient.title}`}
                destination="internal"
                className="mx-auto--mobile"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FeaturedCase
